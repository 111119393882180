<template>
  <div id="room-details" class="room-details center z-depth-0">
    <div id="room-details-body"  class="room-details-body">
      <div class="row">
        <div class="col s12 m6 l7">
          <h5 class="grey-text text-darken-2">{{ $t('room.about_flat') }}</h5>
          <ul>
            <img :src="room.image.path" class="room-image responsive-img materialboxed" alt="">
            <li>
              <div class="row">
                <div class="col s12 m6 l4" style="text-align: left">{{$t('price_by_day')}}: € {{ room.price }}</div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col s12" style="text-align: left">{{ room.description }}</div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col s12" style="text-align: left">{{ $t('room.further_info') }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col s12 m6 l5">
          <div class="calendar-wrapper center">
            <h5 class="grey-text text-darken-2">{{ $t('room.in_out') }}</h5>
            <form>
              <div class="input-field">
                <datepicker :disabledDates="disabledDates" @confirm="confirmData"></datepicker>
                <!-- button or link to trigger modal  -->
              </div>
            </form>
            <div class="center">
              <div class="input-field">
                <a @click="goToHome"
                   class="btn grey z-depth-0">
                  <i class="fa fa-lock"></i> {{ $t('cancel') }}
                </a>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section">
      <Footer></Footer>
    </div>
    <div class="fixed-action-btn">
      <a href="#" class="btn-floating orange lighten-2 btn-large tooltipped" data-position="left" data-tooltip="Navigation" Style="opacity:0.7">
        <i class="material-icons">navigation</i>
      </a>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import VueHotelDatepicker from '@/calendar/components/VueHotelDatepicker'
import homeMixin from '@/mixins/homeMixin'
import roomMixin from '@/mixins/roomMixin'
import Footer from '@/components/home/Footer'

import { DateTime } from 'luxon'

export default {
  name: 'RoomDetails',

  data(){
    return{
      dates: null,
      reservations: []
    }
  },

  components: {
    Footer,
    datepicker: VueHotelDatepicker
  },
  created() {
    // set the details for the selected room for reservation
    this.$store.dispatch('rooms/setRoomDetails', this.$route.params.id)
    // get room reservations data by now. These will be used to disable reserved data ranges
    this.reservations = this.$store.getters['rooms/roomDetails'].reservation
    // console.log('Length of Reservations: ' + this.reservations.length)
  },

  mounted () {
    // console.log('***In Room Details***')
    window.scrollTo(0, 0)
    const $ = window.jQuery
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },

  methods: {
    getDisabledDates (startDate, endDate) {
      let dates = []
      // console.log('startDate: ' + startDate)
      let currentDate = startDate
      let addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        // console.log(date)
        return date
      }
      // to disabled dates without chick off date
      while (currentDate < endDate) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)
      }
      return dates
    },

    // selected dates from calendar
    confirmData(dates){
      // ... not really to dispatch action ... only to convert, calculate and store need data in state
      // yyyy/MM/dd dates format
      // console.log('confirmDates in Room Details')
      // Set Dates to confirm and toConfirm Status to true
      this.$store.dispatch('rooms/setConfirmDates', dates)
      // console.log(this.getConfirmStatus)  ... test 2 ij
      this.getConfirmStatus ? this.$router.push({ name: 'reservation'}) : this.goToHome()
    }
  },

  computed: {
     // true if start, end dates, between and total price are mutated
    getConfirmStatus(){
      return this.$store.getters['rooms/toConfirm']
    },

    disabledDates () {
      // console.log('Computed')
      const datesToDisable = []
      const ranges = this.reservations
      ranges.forEach((range) => {
        let checkInDate = range.startdate.slice(0, 10)
        let checkOutDate = range.enddate.slice(0, 10)
        let dates = this.getDisabledDates(new Date(checkInDate), new Date(checkOutDate))
        dates.forEach((date) => {
          // DateTime.fromISO(date.toISOString()).toFormat('yyyy/mm/dd')
          // datesToDisable.push(date.toISOString().slice(0, 10))
          datesToDisable.push(DateTime.fromISO(date.toISOString()).toFormat('yyyy/MM/dd'))
        })
      })
      // console.log(datesToDisable)
      return datesToDisable
    }
  },

  mixins: [homeMixin, roomMixin]
}
</script>

<style scoped>

  #room-details-body {
    width: 100%;
    display: block;
    position: relative;
  }
  .room-image{
    margin-bottom: 10px;
  }
</style>
